<template lang="pug">
.design-list__pagination.fade(
  v-if="activeTotalPages > 1"
  :class="{ 'is-dark': isDarkMode }"
)
  BaseButton.btn-next(
    v-if="hasPrev"
    :is-text="true"
    :is-short="true"
    :is-disabled="!hasPrev"
    padding="8px"
    @click="prevPage"
  ) Prev
  template(v-for="page in activeTotalPages")
    //- only show the 5 prev and 5 next page
    template(v-if="page >= activePage - 5 && page <= activePage + 5")
      BaseButton.btn-page(
        :extra-class="page === activePage ? 'is-active' : ''"
        :is-text="true"
        :is-short="true"
        padding="8px"
        @click="goToPage(page)"
      ) {{ page }}
  BaseButton.btn-next(
    v-if="hasNext"
    :is-text="true"
    :is-short="true"
    :is-disabled="!hasNext"
    padding="8px"
    @click="nextPage"
  ) Next
</template>

<script>
import { mapState } from 'vuex';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'DesignListPagination',
  props: {
    activePage: { type: Number, default: 1 },
    isBrandedTab: { type: Boolean, default: false },
  },
  mixins: [darkModeMixin],
  computed: {
    ...mapState('assetsSidebar', [
      'activeDesignTab',
      'filteredBrandedDesignsTotalPages',
      'filteredDesignsTotalPages',
    ]),
    hasPrev() {
      return this.activePage > 1;
    },
    hasNext() {
      return this.activePage < this.activeTotalPages;
    },
    activeTotalPages() {
      return this.isBrandedTab ? this.filteredBrandedDesignsTotalPages : this.filteredDesignsTotalPages;
    },
  },
  methods: {
    goToPage(page) {
      this.$emit('change', page);
    },
    prevPage() {
      const prevPage = this.activePage - 1;
      this.goToPage(prevPage);
    },
    nextPage() {
      const nextPage = this.activePage + 1;
      this.goToPage(nextPage);
    },
  },
};
</script>

<style lang="scss">
.design-list__pagination {
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 0 20px 0;
  // box-shadow: 0px -10px 20px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  background: none;
  z-index: 3;

  .btn {
    margin: 0 2px;
    background: transparent;
    transition: all 0.25s ease-in-out;

    &.is-active {
      pointer-events: none;
      cursor: auto;
    }
  }

  .btn-page {
    padding-left: 4px;
    padding-right: 4px;
    min-width: 30px;

    @include mobile() {
      min-width: 30px;
    };
  }

  .btn--next {
    @include mobile() {
      padding: 8px 12px;
    };
  }

  &.is-dark {
    .btn {
      color: $light;

      &:hover,
      &:active,
      &.is-active {
        .btn__circle {
          background: $darkGrey600;
        }
      }
    }
  }
}
</style>
