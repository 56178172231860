<template lang="pug">
.modular-template
  //- Designer won't see the option to select scenes
  //- template(#top)
  .modular-template__top
    button.modular-template__back(
      type="button"
      @click="backToDesignListing"
    )
      i.icon-left-arrow
    BaseButton.modular-template__add-button(
      :class="{'is-disabled': isButtonDisabled, 'is-dark': isDarkMode}"
      :is-canvas="true"
      is-primary=true
      :is-disabled="isButtonDisabled"
      @click="checkTemplateWarning"
    ) Add Scenes

  //- Warning if the duration is already reached max
  p.warning-text(v-if="showAlert")
    i.icon.icon-alert
    span(v-html="` Maximum duration is ${maxAllowedDuration}s`")

  draggable.modular-template__content(
    v-model="modularScenes"
    :sort="getIsTemplate"
    filter=".btn-add-modular-scene"
    @change="sceneMoved"
  )
    BasePreloader.modular-template__preloader(
      :type="2"
      v-if="isLoading"
    )
    template(v-if="!isLoading")
      //- For designer, they can drag and drop to the modular category
      //- Normal user will be only be able to select scenes for the template
      ModularTemplateScene(
        v-for="scene in modularScenes"
        :key="scene.id"
        :background="scene.background"
        :description="scene.description"
        :duration="scene.duration"
        :id="scene.id"
        :scene="scene"
        :is_hero_w_bg="scene.is_hero_w_bg"
        :is_hero_wo_bg="scene.is_hero_wo_bg"
        :selectedScenesIds="selectedScenesIds"
        :reachMaxDuration="reachMaxDuration"
        :availableDuration="availableDuration"
        :width="scene.width || getCanvasSize.width"
        :height="scene.height || getCanvasSize.height"
        @deleteScene="deleteScene"
        @close-scene-builder="closeOverlay"
        @select="selectScene"
        @duplicateScene="duplicateScene"
        @addScene="addScene"
        @click-disabled="showAlert = true"
      )

  TemplateWarning(
    v-if="showTemplateWarning"
    @cancel="showTemplateWarning = false"
    @add="addTemplateScene"
    @replace="replaceTemplateScene"
  )
</template>

<script>
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';
import draggable from 'vuedraggable';
import { sceneFormat } from '@/assets/scripts/variables';
import ProjectApi from '@/services/api/ProjectApi';
import TemplateApi from '@/services/api/TemplateApi';
import { randomId } from '@/assets/scripts/utilities';

import templateMixins from '@/components/mixins/template-mixins';
import newSceneMixins from '@/components/mixins/new-scene-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';
import storyboardMixin from '@/components/mixins/storyboard-mixins';

import ModularTemplateScene from '@/components/ProjectCanvas/PageOverlay/children/ModularTemplateScene.vue';
import TemplateWarning from '@/components/ProjectCanvas/AssetsSidebar/children/TemplateWarning.vue';

export default {
  name: 'ModularTemplate',
  components: {
    ModularTemplateScene,
    draggable,
    TemplateWarning,
  },
  mixins: [templateMixins, newSceneMixins, darkModeMixin, storyboardMixin],
  data() {
    return {
      isShow: true,
      isButtonDisabled: true,
      reachMaxDuration: false,
      totalDuration: 0,
      availableDuration: 999,
      maxAllowedDuration: 999,
      // sampleScenes: { // sample format of scenes
      //   id: 0,
      //   background: {
      //     color: '#fff',
      //   },
      //   duration: 3,
      //   modular_category_id: null,
      //   preview: null,
      //   description: null,
      //   is_hero_w_bg: false,
      //   is_hero_wo_bg: false,
      // },
      scenes: {},
      scenesIds: [],
      selectedScenesIds: [],
      newSelectedIdTotal: 0,
      confirmedScenes: [],
      isLoading: true,
      showAlert: false,
      showTemplateWarning: false,
      showMissingCategoryWarning: false,
    };
  },
  computed: {
    ...mapState(['showLastSelectedTemplate', 'showSidebar', 'isAddNewScene']),
    ...mapState('canvasElements', [
      'selectedTemplateModular',
      'templateModularScenesList',
      'lastSelectedTemplateId',
      'elementsList',
      'hasAutoWidthElement',
    ]),
    ...mapGetters(['getIsTemplate']),
    ...mapGetters('assetsSidebar', ['getActiveGroup']),
    ...mapGetters('userData', ['isFreeUser', 'isDesigner']),
    ...mapGetters('canvasElements', [
      'getScenes',
      'getModularTemplateScenes',
      'getLastSelectedTemplateScenes',
      'getScenesElementsById',
      'getProjectId',
      'getActiveSceneId',
      'getSceneById',
      'getProjectDetails',
      'getCurrentSceneDuration',
      'getAllScenesDuration',
      'getCanvasSize',
    ]),
    modularScenes: {
      get() {
        // TODO: fixed for designer
        // return this.scenesIds.map(id => this.scenes[id]);
        console.log('modularScenes', this.getModularTemplateScenes);
        return this.getModularTemplateScenes;

        // let scenes = this.getScenes;

        // // for template, need to add an add scene button
        // console.log('scenes are', JSON.parse(JSON.stringify(scenes)))
        // if (this.getIsTemplate) {
        //   if (scenes.length === 0 || scenes[0].id !== 0) scenes.unshift(this.sampleScenes);
        // } else {
        //   scenes = this.getModularTemplateScenes;
        // }
        // return scenes;
      },
      set() {
        // console.log('reorder value', value.map((e) => e.id));
        // if (value.length && value[0].id === 0) {
        //   value.splice(0, 1);
        // }
        // console.log('remove value', value.map((e) => e.id));
        // this.updateScenes(value);
      },
    },
    modularIcon() {
      return 'icon-template-menu';
    },
    modularTitle() {
      return 'Template';
    },
    modularSubtitle() {
      return 'Mix and match your story';
    },
  },
  methods: {
    ...mapMutations([
      'setShowSidebar',
      'setShowDesignContainer',
      'setThirtySecondsWarning',
      'setShowModularTemplate',
      'setShowCanvasPreloader',
      'setShowLastSelectedTemplate',
      'setShowMaxSceneWarning',
    ]),
    ...mapMutations('assetsSidebar', ['setActiveGroup', 'setShowDesignPreloader']),
    ...mapMutations('canvasElements', [
      'addModularCategory',
      'addSceneToArray',
      'deleteSceneFromArray',
      'setModular',
      'updateActiveSceneId',
      'updateScenes',
      'updateLastSelectedTemplateId',
      'updateCanvasSize', // mutation so that it doesn't save history
      'updateHasAutoWidthElement',
      'updateCanvasSizeWithTemplate',
    ]),
    ...mapActions('canvasElements', ['updateScenesElements', 'getStoryBoardPreview']),
    closeOverlay(stayDesign) {
      this.setShowModularTemplate(false);
      this.setShowLastSelectedTemplate(false);
      if (!this.showSidebar) this.setShowSidebar(true); // if sidebar is hidden, show it
      if (!stayDesign && this.getActiveGroup === 'designs') this.setActiveGroup('texts'); // if the active group is design, change it to text
    },
    checkTemplateWarning() {
      if (this.isAddNewScene) {
        // if user add from right sidebar add new scene button
        this.addTemplateScene();
      } else if (this.elementsList.allIds.length === 0) {
        // if there is no element, don't need warning
        this.replaceTemplateScene();
      } else {
        this.showTemplateWarning = true;
      }
    },
    addTemplateScene() {
      const hitMax = this.checkForMaxScenes();
      if (hitMax) return;
      this.confirmNewTemplate();
    },
    replaceTemplateScene() {
      const hitMax = this.checkForMaxScenes(true);
      if (hitMax) return;
      this.confirmNewTemplate(true);
    },
    confirmNewTemplate(isReplace) {
      // NOTE!!
      // if you update this function, update the chooseTemplate() in CategoryItem.vue and BrandedDesignItem.vue too

      this.setShowCanvasPreloader(true);
      this.newSelectedIdTotal = 0;
      this.confirmedScenes.length = 0;

      // console.log('this.confirmedScenes.length', this.confirmedScenes.length);
      const params = {
        project_id: this.getProjectId,
        template_scenes: this.selectedScenesIds,
      };

      if (isReplace && !this.isAddNewScene) {
        // if replacing, add active_scene_id
        params.active_scene_id = this.getActiveSceneId;
      }

      if (!this.isAddNewScene && !isReplace) {
        // if it's not from right sidebar add scene button, add after_scene_id
        params.after_scene_id = this.getActiveSceneId;
      }

      if (this.elementsList.allIds.length === 0) {
        // if there is no elements, auto change the canvas dimension
        params.change_canvas_dimension = true;
      }

      const templateId = this.selectedTemplateModular.id;

      ProjectApi.chooseTemplate(templateId, params)
        .then((response) => {
          if (response.data.success) {
            this.applyDesign({
              response,
              isReplace,
              assignModularAsLastTemplate: true,
            });

            const { scenes } = response.data.results;

            console.log('scenes', scenes);

            for (let i = 0; i <= scenes.length; i += 1) {
              if (scenes[i]) {
                // use websocket
                this.wsEventGenerateStoryboardThumbnail(this.getProjectId, this.getIsTemplate, scenes[i].id);

                // setTimeout(() => {
                //   this.getStoryBoardPreview({
                //     id: this.getProjectId,
                //     sceneId: scenes[i].id,
                //     isTemplate: this.getIsTemplate,
                //   });
                // }, i * 3000);
              }
            }

            this.updateCanvasSizeWithTemplate({
              width: this.getCanvasSize.width,
              height: this.getCanvasSize.height,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setShowCanvasPreloader(false);
        });

      this.setShowModularTemplate(false);
      this.setShowLastSelectedTemplate(false);
      this.setModular(true);
    },
    checkTotalSelectedId(total) {
      console.log('checkTotalSelectedId', this.newSelectedIdTotal, total);
      if (this.newSelectedIdTotal === total) {
        this.updateScenesElements({ newScenes: this.confirmedScenes });
      }
    },
    updateModularScenes() {
      let scenes = this.getScenes;
      if (!this.getIsTemplate) {
        // if the selected template is same last selected template id, use the last selected scenes
        if (
          this.selectedTemplateModular.id === this.lastSelectedTemplateId
          || this.showLastSelectedTemplate
        ) scenes = this.getLastSelectedTemplateScenes;
        else scenes = this.getModularTemplateScenes;
      }
      this.scenesIds.length = 0;

      // eslint-disable-next-line
      for (let i = 0; i < scenes.length; i++) {
        const scene = scenes[i];
        this.scenes[scene.id] = scene;
        this.scenesIds.push(scene.id);
      }
      console.log('scenes loading');
      this.isLoading = false;
    },
    duplicateScene(sceneId) {
      if (this.isDesigner) this.addScene(true, sceneId);
    },
    addScene(isDuplicate, duplicatedId) {
      if (!this.getIsTemplate) return;

      const projectId = this.getProjectId;
      const params = JSON.parse(JSON.stringify(sceneFormat[0]));
      const activeCategory = null;

      params.modular_category_id = activeCategory;

      if (isDuplicate) {
        const scene = this.scenes[duplicatedId];
        // console.log('duplicatedId', duplicatedId, scene.elements)
        const elements = JSON.parse(JSON.stringify(this.getScenesElementsById(duplicatedId)));
        // console.log('elements are', JSON.parse(JSON.stringify(elements)))
        // replace the element id when duplicating
        elements.forEach((element) => {
          // eslint-disable-next-line
          element.data.id = randomId();
        });
        // console.log('elements are', elements);
        params.scene_elements = elements;
        params.background = JSON.parse(JSON.stringify(scene.background));
        params.transition = JSON.parse(JSON.stringify(scene.transition));
        params.duration = scene.duration;
        params.template_description = scene.template_description;
        params.modular_category_id = scene.modular_category_id;
      }

      TemplateApi.addProjectScene(projectId, params)
        .then((response) => {
          // console.log('completed', response.data);
          if (response.data.success) {
            // add scene to vuex
            const result = response.data.results;
            const newId = result.id;

            this.addSceneToArray({
              isModular: true,
              activeCategory: null,
              newId,
              background: result.background,
              elements: result.elements,
              transition: result.transition,
            });

            // add scene to this.scenes listing
            this.scenes[newId] = this.getSceneById(newId);
            this.scenesIds.push(newId);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkMaxDuration() {
      // sometimes the subtraction will give a .9999 result, hence the math round
      this.availableDuration = Math.round((this.maxAllowedDuration - this.totalDuration) * 10) / 10;
      this.reachMaxDuration = this.totalDuration >= this.maxAllowedDuration;
      this.showAlert = !!this.reachMaxDuration; // show alert if already reach max duration
    },
    updateTotalDuration() {
      // eslint-disable-next-line
      const selectedScenes = this.selectedScenesIds.map((sceneId) => {
        for (let i = 0; i < this.modularScenes.length; i += 1) {
          if (this.modularScenes[i].id === sceneId) {
            return this.modularScenes[i];
          }
        }
      });
      console.log('selectedScenes', this.scenes, this.modularScenes, selectedScenes);
      const duration = selectedScenes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.duration,
        0,
      );

      // allow user to select scenes if they select at least one scene
      if (duration > 0) this.isButtonDisabled = false;
      else this.isButtonDisabled = true;

      this.totalDuration = duration;

      this.checkMaxDuration();
    },
    // addNewCategory() {
    //   this.isAddingNewCategory = true;
    //   setTimeout(() => {
    //     console.log('this.$refs.newCategory', this.$refs.newCategory)
    //     if (this.$refs.newCategory) this.$refs.newCategory.focus();
    //   }, 10);
    // },
    // confirmNewCategory(e) {
    //   const name = e.target.value;

    //   if (name) this.addModularCategory({ name });
    //   this.isAddingNewCategory = false;
    // },
    selectScene(sceneId) {
      // if it's already exist, deselect the scene
      if (this.selectedScenesIds.indexOf(sceneId) >= 0) {
        const sceneIndex = this.selectedScenesIds.indexOf(sceneId);
        this.selectedScenesIds.splice(sceneIndex, 1);
      } else {
        if (
          this.getScenes.length + this.selectedScenesIds.length + 1
          > process.env.VUE_APP_MAX_SCENES
        ) {
          this.setShowMaxSceneWarning(true);
          return;
        }
        this.selectedScenesIds.push(sceneId);
      }
      this.updateTotalDuration();
    },
    deleteScene(sceneId) {
      if (this.selectedScenesIds.indexOf(sceneId) >= 0) {
        const selectedSceneIndex = this.selectedScenesIds.indexOf(sceneId);
        this.selectedScenesIds.splice(selectedSceneIndex, 1);
      }
      const sceneIndex = this.scenesIds.indexOf(sceneId);
      this.scenesIds.splice(sceneIndex, 1);

      delete this.scenes[sceneId];

      this.deleteSceneFromArray(sceneId);

      if (this.modularScenes.length === 1) {
        this.closeOverlay(true);
      }
    },
    resetModularModal() {
      console.log('resetModularModal', this.getActiveGroup);
      // this.closeOverlay(true);
      this.setShowSidebar(false);
      if (this.getActiveGroup === 'designs') {
        // this.getElements();
        this.setShowDesignContainer(true);
      } else {
        this.setActiveGroup('designs');
      }
      this.setShowDesignPreloader(false);
      this.isLoading = true; // need to set this because sometimes vue doesnt destroy the modularTemplate
    },
    sceneMoved(moved) {
      this.reorderScene(moved.moved.oldIndex - 1, moved.moved.newIndex - 1);

      const params = {
        template_name: this.getProjectDetails.name,
        scenes: this.modularScenes.map(scene => scene.id),
      };

      TemplateApi.update(this.getProjectId, params)
        .then((response) => {
          // console.log('update scene order', response)
          if (!response.data.success) {
            // revert to original arrangement
            this.reorderScene(moved.moved.newIndex - 1, moved.moved.oldIndex - 1);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    reorderScene(from, to) {
      this.modularScenes.splice(to, 0, this.modularScenes.splice(from, 1)[0]);
      this.updateScenes(this.modularScenes);
      this.$forceUpdate();
    },
    backToDesignListing() {
      this.setShowModularTemplate(false);
      this.setShowLastSelectedTemplate(false);
    },
  },
  beforeMount() {
    // need to be beforeMount because getUserLevelModularCategories relies on this.modularCategories scenes
    // reset all values
    this.scenes = {};
    this.scenesIds.length = 0;
    this.isLoading = true;

    // for normal user, set the available duration
    if (!this.isDesigner) {
      this.maxAllowedDuration = this.maxDurationProject;
      this.availableDuration = this.maxAllowedDuration;
    }
  },
  mounted() {
    console.log(
      'modular template mounted',
      this.selectedTemplateModular.id,
      this.lastSelectedTemplateId,
      this.isLoading,
    );
    // check if the modular template id selected is the same as last template id
    // if different, wait for the scenes list to get updated before calling the updateModularScenes
    if (!this.getIsTemplate && this.selectedTemplateModular.id === this.lastSelectedTemplateId) {
      this.updateModularScenes();
    }
    // if user click from last selected template
    if (this.showLastSelectedTemplate) this.updateModularScenes();
  },
  watch: {
    templateModularScenesList: {
      handler() {
        // wait until the api return before updating modular scenes
        console.log('templateModularScenesList updated');
        this.updateModularScenes();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.modular-template {
  height: 100%;

  .btn-done-modular {
    margin-left: auto;
    min-width: 160px;
  }

  .warning-text {
    font-size: 0.875em;
    margin: 0;
    color: $red;
    text-align: right;
    position: absolute;
    top: 35px;
    right: 30px;
  }
}

.modular-template__list {
  width: 100%;

  &.is-draggable {
    .modular-template__categoryitem {
      position: relative;

      &::before {
        content: $icon-options;
        font-family: 'icomoon';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.modular-template__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;

  .modular-template__back {
    padding: 5px;
    color: var(--blue-white);
    font-size: 1.5rem;
    cursor: pointer;
  }

  .modular-template__add-button {
    background: $blue2;
    width: 185px;
    height: 38px;
    font-size: 0.8125rem;

    &:hover {
      color: $blue2;
    }

    &.is-disabled {
      background: $disabledGrey;

      &.is-dark {
        background: $darkGrey600;
        color: $darkGrey200;
      }
    }
  }
}

.modular-template__content {
  padding-top: 40px;
  width: 100%;
  display: flex;
  height: calc(100% - 30px);
  flex-wrap: wrap;
  align-content: flex-start;

  .preloader {
    width: 100%;
  }
}

.modular-template__preloader {
  margin-top: -20px;
  height: calc(100% - 20px);
}
</style>
