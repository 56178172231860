<template lang="pug">
.design-topbar__filters.design-topbar__dropdown(
  :class='{ "is-active": isActive, "has-value": hasFilter }',
  v-click-outside='onClickOutside'
)
  BaseButton.dropdown__button(
    type='button',
    :is-canvas="true"
    :is-hollow='!hasFilter',
    :is-primary-hollow='hasFilter',
    padding="20px"
    :icon-after='iconAfter',
    @click='onClick'
  )
    span.text {{ filterText }}
    i.icon.icon-cancel(v-if='hasFilter', @click='removeFilter')

  .dropdown__content.design-filter
    .design-filter__group(v-for='group in designCategoriesGroup')
      h4.design-filter__name {{ group.type }}
      ul.design-filter__list
        li.design-filter__item(
          v-for='categoryId in group.children',
          :class='{ "design-filter__item--two-column": isTwoColumn(group.children.length) }'
        )
          button.design-filter__button(
            type='button',
            @click='changeActiveCategory(categoryId)'
          ) {{ categoryName(categoryId) }}
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import vClickOutside from 'v-click-outside';

export default {
  name: 'DesignFilters',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    ...mapState('assetsSidebar', ['designCategoriesGroup', 'designCategories']),
    ...mapGetters('assetsSidebar', [
      'getActiveCategory',
      'getDesignCategories',
      'getDesignCategoriesById',
    ]),
    hasFilter() {
      return this.getActiveCategory && this.getActiveCategory.slug !== 'all';
    },
    filterText() {
      return this.hasFilter ? this.getActiveCategory.name : 'Filter';
    },
    iconAfter() {
      return this.hasFilter ? '' : 'icon-down-arrow';
    },
  },
  mounted() {
    this.$root.$on('set-design-category', (category) => {
      const { slug } = category;
      const selectedCategory = this.getDesignCategories.find(cat => cat.slug === slug);

      if (selectedCategory && selectedCategory.id) {
        this.changeActiveCategory(selectedCategory.id);
      }
    });
  },
  watch: {
    isActive(val) {
      this.$emit('is-open-filter', val);
    },
  },
  methods: {
    ...mapMutations('assetsSidebar', [
      'setActiveCategory',
      'setActiveKeyword',
      'clearActiveCategory',
      'setShowDesignPreloader',
    ]),
    onClick() {
      this.isActive = !this.isActive;
    },
    removeFilter(e) {
      e.preventDefault();
      if (this.hasFilter) {
        this.setShowDesignPreloader(true);
        this.clearActiveCategory();
        this.$emit('filter');
      }
    },
    onClickOutside() {
      this.isActive = false;
    },
    categoryName(categoryId) {
      const category = this.getDesignCategoriesById(categoryId);
      return category.name;
    },
    changeActiveCategory(categoryId) {
      this.setShowDesignPreloader(true);
      const category = this.getDesignCategoriesById(categoryId);
      this.setActiveCategory(category);
      this.setActiveKeyword('');
      this.isActive = false;
      this.$emit('filter');
    },
    isTwoColumn(length) {
      return length < 5 && length !== 3;
    },
  },
};
</script>

<style lang="scss">
.design-filter {
  position: absolute;
  left: 0;
  padding: 20px 20px 20px 80px;
  font-size: 0.875rem;
  columns: 2;
  box-shadow: 0px 18px 18px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out, transform 0.25s ease-in-out;

  &.is-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.design-filter__group {
  margin-bottom: 2em;
  break-inside: avoid;
}

.design-filter__name {
  margin: 0;
  padding-left: 8px;
  font-size: 1rem;
  color: var(--black-white);
}

.design-filter__list {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 0;
}

.design-filter__item {
  width: 33%;

  &--two-column {
    width: 42%;
  }
}

.design-filter__button {
  text-align: left;
  padding: 8px;
  transform: color 0.25s ease-in-out;
  color: var(--black-white);

  &:hover {
    color: var(--blue700-green700);
  }
}
</style>
