<template lang="pug">
.design-topbar__colors.design-topbar__dropdown(
  :class="{ 'is-active': isActive, 'has-value': hasColor }"
  v-click-outside="onClickOutside"
)
  BaseButton.dropdown__button(
    type="button"
    :is-hollow="!hasColor"
    :is-primary-hollow="hasColor"
    :is-canvas="true"
    padding="20px"
    :icon-after="iconAfter"
    @click="onClick"
  )
    span Colour
    span.color(
      v-if="hasColor"
      :title="colorText"
      :style="{ backgroundColor: getActiveDesignCategoriesColor.slug }"
    )
    i.icon.icon-cancel(
      v-if="hasColor"
      @click="removeColor"
    )
  .dropdown__content.dropdown__content--short
    button.design-topbar__color(
      v-for="color in getDesignCategoriesColor"
      :title="color.name"
      @click="changeActiveColor(color.id)"
    )
      span.color(:style="{ backgroundColor: color.slug }")


</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import vClickOutside from 'v-click-outside';

export default {
  name: 'DesignColors',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    ...mapGetters('assetsSidebar', ['getDesignCategoriesColor', 'getActiveDesignCategoriesColor']),
    hasColor() {
      return this.getActiveDesignCategoriesColor && !!this.getActiveDesignCategoriesColor.slug;
    },
    colorText() {
      return this.hasColor ? this.getActiveDesignCategoriesColor.name : '';
    },
    iconAfter() {
      return this.hasColor ? '' : 'icon-down-arrow';
    },
  },
  watch: {
    isActive(val) {
      this.$emit('is-open-color', val);
    },
  },
  methods: {
    ...mapMutations('assetsSidebar', [
      'setActiveDesignCategoriesColorId',
      'resetActiveDesignCategoriesColorId',
    ]),
    onClick() {
      this.isActive = !this.isActive;
    },
    removeColor(e) {
      e.preventDefault();
      if (this.hasColor) {
        this.$emit('filter');
        this.resetActiveDesignCategoriesColorId();
      }
    },
    onClickOutside() {
      this.isActive = false;
    },
    changeActiveColor(colorId) {
      this.$emit('filter');
      this.setActiveDesignCategoriesColorId(colorId);
      this.isActive = false;
    },
  },
};
</script>

<style lang="scss">
.design-topbar__colors {
  .dropdown__button {
    .color {
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: relative;
      margin-left: 4px;
      margin-right: 0;
    }

    .btn__content {
      display: flex;

      span {
        display: flex;
        align-items: center;
      }
    }
  }
}

.design-topbar__color {
  width: 16px;
  height: 16px;
  padding: 0;
  display: inline-block;
  margin: 10px;
  position: relative;
  border-radius: 50%;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);

  .color {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    border: 1px solid $lightGrey300;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: transform 0.25s ease-in-out;
    transform-origin: center center;
  }

  &:hover {
    &::before {
      transform: scale(1.4);
    }
  }
}
</style>
