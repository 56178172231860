<template lang="pug">
.design-list(
  :class='{ "has-prev-item": hasPrevItem, "has-next-item": hasNextItem }'
)
  BasePreloader.design-list__preloader(v-if='showPreloader || isCatchingLastPageOpened')
  div(v-if='filteredLength' :class="{'design-list--hidden': isCatchingLastPageOpened}")
    VueSlickCarousel(
      :class='{ "is-dark": isDarkMode }',
      ref='slick',
      :key='currentKey',
      v-bind='slickOptions',
      @afterChange='handleAfterChange',
      @beforeChange='handleBeforeChange'
    )
      .design-list__item(
        v-for='(template, index) in slickContent',
        :key='template.slug'
      )
        BrandedDesignItem(
          v-if='isBrandedTab',
          :index='index',
          :id='template.id',
          :width='template.width',
          :height='template.height',
          :is-visible='checkIsActivePage(index)',
          :selected-team-brand-id="selectedTeamBrandId"
          @branded-template-height='setBrandedTemplateHeight'
        )
        DesignItem(
          v-if='!isBrandedTab',
          v-bind='template',
          @click='selectTemplate',
          @choose='chooseTemplate',
          @design-template-height='setDesignTemplateHeight'
        )
    DesignListPagination(
      :activePage='activePage',
      :isBrandedTab='isBrandedTab',
      @change='changeActivePage'
    )
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { designsPerRow, designsRows } from '@/assets/scripts/variables';

import ProjectApi from '@/services/api/ProjectApi';
import TemplateApi from '@/services/api/TemplateApi';

import DesignItem from '@/components/ProjectCanvas/AssetsSidebar/children/DesignItem.vue';
import BrandedDesignItem from '@/components/ProjectCanvas/AssetsSidebar/children/BrandedDesignItem.vue';
import DesignListPagination from './children/DesignListPagination.vue';

import slickMixin from '@/components/mixins/slick-mixins';
import templateMixins from '@/components/mixins/template-mixins';
import helperMixins from '@/components/mixins/helper-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'DesignList',
  components: {
    DesignItem,
    BrandedDesignItem,
    DesignListPagination,
  },
  mixins: [slickMixin, templateMixins, helperMixins, darkModeMixin],
  data() {
    return {
      currentSlide: 0,
      currentKey: 0,
      slickContent: [],
      showPreloader: false,
      brandedTemplateHeight: -1,
      designTemplateHeight: -1,
    };
  },
  props: {
    isBrandedTab: { type: Boolean, default: false },
    activeFilteredDesign: { type: Array, default: () => [] },
    selectedTeamBrandId: { type: Number, default: 0 },
  },
  computed: {
    ...mapState(['isAddNewScene', 'showStoryboardOverlay', 'previousDesignContainerPage']),
    ...mapState('assetsSidebar', [
      'activeDesignTab',
      'filteredDesigns',
      'filteredBrandedDesigns',
      'filteredDesignsTotalPages',
      'filteredBrandedDesignsTotalPages',
    ]),
    ...mapState('canvasElements', [
      'elementsList',
      'selectedTemplateModular',
      'lastSelectedTemplateId',
    ]),
    ...mapGetters('assetsSidebar', [
      'getActiveKeyword',
      'getActivePresetFormat',
    ]),
    ...mapGetters('canvasElements', ['getProjectId', 'getActiveSceneId']),
    filteredLength() {
      return this.isBrandedTab
        ? this.filteredBrandedDesigns.length
        : this.filteredDesigns.length;
    },
    proportion() {
      /* eslint-disable */
      if (this.getActivePresetFormat.width > this.getActivePresetFormat.height)
        return 'landscape';
      if (this.getActivePresetFormat.width < this.getActivePresetFormat.height)
        return 'vertical';
      return 'square';
      /* eslint-enable */
    },
    isScreenRequireMoreSlide() {
      const screenSize = window && window.screen.width;
      // some screen across 1600px & 1900px width,
      // sometimes have height around 825px that needs more slide so the design overlay won't go too tall.
      return screenSize && screenSize > 1600 && screenSize < 1900;
    },
    slidesPerRow() {
      const slides = designsPerRow[this.activeDesignTab][this.proportion];
      return this.isScreenRequireMoreSlide ? slides + 1 : slides;
    },
    rows() {
      return designsRows[this.activeDesignTab][this.proportion];
    },
    slickOptions() {
      return {
        slidesPerRow: this.slidesPerRow,
        rows: this.rows,
        infinite: false,
      };
    },
  },
  mounted() {
    if (this.previousDesignContainerPage && !this.selectedTeamBrandId) this.catchLastPageOpened();
  },
  methods: {
    ...mapActions('canvasElements', [
      'updateScenesElements',
      'addTextTemplateToCanvas',
    ]),
    ...mapMutations([
      'setShowModularTemplate',
      'setShowDesignContainer',
      'setPreviousDesignContainerPage',
      'setShowSidebar',
      'setShowCanvasPreloader',
    ]),
    ...mapMutations('assetsSidebar', ['setFilteredDesigns']),
    ...mapMutations('canvasElements', [
      'updateTemplateModularId',
      'setSelectedTemplateModular',
      'emptyActiveElements',
    ]),
    catchLastPageOpened() {
      this.isCatchingLastPageOpened = true;
      this.currentSlide = this.previousDesignContainerPage;
      this.changeActivePage(this.previousDesignContainerPage);
    },
    shouldFetchDesign(page) {
      const designsPerPage = this.slidesPerRow * this.rows;
      const availableFilteredDesignsPages = Math.ceil(
        this.filteredLength / designsPerPage // eslint-disable-line
      );
      // console.log('shouldFetchDesign', page, availableFilteredDesignsPages, this.filteredDesigns.length, designsPerPage);
      this.currentSlide = page - 1;

      if (
        availableFilteredDesignsPages > 6
        && page >= availableFilteredDesignsPages
      ) {
        return true;
      }
      return false;
    },
    checkFilteredDesigns(page) {
      if (this.shouldFetchDesign(page)) {
        /**
         * show another 6 so that there will still be znext slide
         * for example current page is 1
         * when user click page 6,
         * we want them to see the option to next slide
         */
        this.setFilteredDesigns({
          page: page + 6,
          isBranded: this.isBrandedTab,
        });
      }
    },
    handleAfterChange(currentSlide) {
      // console.log('handleAfterChange', currentSlide);
      this.checkFilteredDesigns(currentSlide + 1);
    },
    changeActivePage(page) {
      // this is when user click on the pagination
      if (this.shouldFetchDesign(page)) {
        this.showPreloader = true;
        this.activePage = page;
        this.checkFilteredDesigns(page);
      } else {
        // slick is looking for index number
        // eslint-disable-next-line
        if (this.$refs.slick) this.$refs.slick.goTo(page - 1);
      }
    },
    checkIsActivePage(index) {
      const designsPerPage = this.slidesPerRow * this.rows;
      const startingIndex = designsPerPage * (this.activePage - 1);
      const endingIndex = startingIndex + designsPerPage;

      if (index >= startingIndex && index < endingIndex) {
        return true;
      }
      return false;
    },
    forceRedraw() {
      this.currentKey += 1;
    },
    selectTemplate(template) {
      const { type, id } = template;

      if (type === 'template') {
        if (this.isAddNewScene) {
          // if user add from right sidebar add new scene button
          this.addTemplateScene(template);
        } else if (this.elementsList.allIds.length === 0) {
          // if there is no element, don't need warning
          this.replaceTemplateScene(template);
        } else {
          this.$root.$emit('show-confirm-design', { id, selectedPage: this.activePage });
        }
      } else if (type === 'modular') {
        this.useModularTemplate(id);
      }
    },
    addTemplateScene(template) {
      this.chooseTemplate(template);
      this.showTemplateWarning = false;
    },
    replaceTemplateScene(template) {
      const selectedTemplate = Object.assign({}, template);
      selectedTemplate.isReplace = true;

      this.chooseTemplate(selectedTemplate);
      this.showTemplateWarning = false;
    },
    useModularTemplate(id) {
      this.setShowModularTemplate(true);
      const templateId = id;
      this.updateTemplateModularId(templateId);
      this.setSelectedTemplateModular({
        id,
        name: this.template_name,
      });

      // if the template modular id is the same as last selected template id,
      // don't need to call the api
      if (this.selectedTemplateModular.id === this.lastSelectedTemplateId) return;

      // get modular details
      TemplateApi.get(templateId, false)
        .then((response) => {
          if (response.data.success) {
            const result = response.data.results;
            const { scenes } = result;
            if (scenes.length) {
              this.updateScenesElements({
                canvasHeight: result.canvas_height,
                canvasWidth: result.canvas_width,
                newScenes: scenes,
                isModularTemplate: result.is_modular,
              });
              console.log('modular scenes updated');
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    chooseTemplate(template) {
      const { type, id, isReplace } = template;
      // NOTE!!
      // if you update this function, update the confirmNewTemplate() in ModularTemplate.vue and BrandedDesignItem.vue too

      if (type !== 'text') {
        this.setShowCanvasPreloader(true);
      }

      // get scenes elements
      const templateId = id;

      const params = {
        project_id: this.getProjectId,
        override_selected_template: !this.showStoryboardOverlay,
      };

      if (isReplace) {
        // if replacing, add active_scene_id
        params.active_scene_id = this.getActiveSceneId;
      }

      if (!this.isAddNewScene && !isReplace) {
        // if it's not from right sidebar add scene button, add after_scene_id
        params.after_scene_id = this.getActiveSceneId;
      }

      if (this.elementsList.allIds.length === 0) {
        // if there is no elements, auto change the canvas dimension
        params.change_canvas_dimension = true;
      }

      console.log('params', params);
      console.log('Choose template', this.getProjectId);

      this.emptyActiveElements();

      ProjectApi.chooseTemplate(templateId, params)
        .then((response) => {
          if (response.data.success) {
            // console.log('response', JSON.parse(JSON.stringify(response.data.results)));

            if (type === 'text') {
              const result = response.data.results;
              // console.log('add text template', element, templateId)
              const { elements } = result.scenes[0];

              // text templates animateOutStart is '' or null so have to assign default 4.
              // when adding exit animation per each element, it throws error.
              elements.forEach((element) => {
                // eslint-disable-next-line
                element.time_out = this.getCurrentSceneDuration;
                // eslint-disable-next-line
                element.timeline_settings.animateOutStart = element.timeline_settings.animateOutStart || 4;
              });

              this.addTextTemplateToCanvas(elements);
            } else {
              this.applyDesign({
                response,
                isReplace,
              });
              this.$root.$emit('zoom-fit-to-screen');
            }
          } else {
            this.setShowCanvasPreloader(false);
          }

          this.trackData('templates', templateId); // track usage of template
        })
        .catch((error) => {
          console.log(error);
          // if failed, show the design option again
          this.setShowCanvasPreloader(false);
          this.setShowSidebar(false);
          // this.getElements();
          this.setShowDesignContainer(true);
        });
    },
    setBrandedTemplateHeight(height) {
      if (this.brandedTemplateHeight === -1) {
        this.brandedTemplateHeight = height;
        const designModalHeight = `${height * this.rows + 135}px`;
        if (designModalHeight) this.$emit('design-modal-height', designModalHeight);
      }
    },
    setDesignTemplateHeight(height) {
      if (this.designTemplateHeight === -1) {
        this.designTemplateHeight = height;
        const designModalHeight = `${height * this.rows + 115}px`;
        if (designModalHeight) this.$emit('design-modal-height', designModalHeight);
      }
    },
  },
  watch: {
    activeFilteredDesign: {
      handler(val, oldVal) {
        // this will get trigger when branded scene preview is loaded
        // therefore, we only compare the value length to trigger this
        if (val && oldVal && val.length === oldVal.length) return;

        this.forceRedraw();
        this.slickContent = val;

        this.$nextTick(() => {
          if (this.$refs.slick) this.$refs.slick.goTo(this.currentSlide, true);

          setTimeout(() => {
            this.showPreloader = false;
          }, 500);

          if (this.isCatchingLastPageOpened) {
            setTimeout(() => {
              // 3000 because this 'activeFilteredDesign' watch is triggered more than 1 time
              // so to avoid UX of loaded then loading again, we loading till all of them done
              this.isCatchingLastPageOpened = false;
            }, 3000);
          }
        });
      },
      deep: true,
      immediate: true,
    },
    activePage() {
      if (this.activePage !== 1) this.setPreviousDesignContainerPage(this.activePage);
    },
    selectedTeamBrandId() {
      this.changeActivePage(1);
    },
  },
};
</script>

<style lang="scss">
.design-list {
  padding-bottom: 20px;

  .slick-track {
    padding-bottom: 0 !important;
  }

  .is-dark {
    .slick-arrow {
      background: $blue700;

      &:focus {
        background: $blue700;
      }

      &:hover {
        background: darken($blue700, 5%);
      }
    }
  }
}

.design-list__item {
  padding: 5px;
  border-radius: $componentBorderRadius;
  overflow: hidden;

  .category-item {
    overflow: hidden;
  }
}

.design-list__preloader {
  position: absolute;
  top: 0;
  left: -20px;
  width: calc(100% + 40px);
  height: calc(100% - 50px);
  background: none;
  z-index: 9;
}

.design-list--hidden {
  opacity: 0;
}
</style>
