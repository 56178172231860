<template lang="pug">
.branded-design--item__container(
  :class="{ 'is-loaded': isLoaded, 'is-visible': isVisible }"
)
  BasePreloader(
    :type=11
    v-if="!isLoaded"
  )
  .branded-design--item(
    :style="brandedDesignPreviewStyle"
  )
    .branded-design__preview(
      ref="image"
      @click="selectTemplate"
    )
      CanvasScene(
        v-if="!isRequestingApi && isVisible"
        hasWatermark=false
        :elements="elements"
        sceneClass="branded-design__canvas"
        :scale="sceneScale"
        :background="scene.background"
        isScenePreview=true
        :showMidScene="true"
        :duration="scene.duration"
        :id="scene.id"
        :sceneWidth="width"
        :sceneHeight="height"
        :isBrandedDesign="true"
        @scene-loaded="isLoaded = true"
      )
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import CollectionsApi from '@/services/api/CollectionsApi';
import ProjectApi from '@/services/api/ProjectApi';

import { groups } from '@/assets/scripts/enums';

import CanvasScene from '@/components/ProjectCanvas/CanvasContainer/children/CanvasScene.vue';

import templateMixins from '@/components/mixins/template-mixins';
import helperMixins from '@/components/mixins/helper-mixins';
import newSceneMixins from '@/components/mixins/new-scene-mixins';

export default {
  name: 'BrandedDesignItem',
  components: {
    CanvasScene,
  },
  mixins: [templateMixins, helperMixins, newSceneMixins],
  data() {
    return {
      scene: {},
      isRequestingApi: true,
      imageWidth: 100,
      imageHeight: 100,
      isLoaded: false,
    };
  },
  props: {
    id: [String, Number],
    index: { type: Number, default: -1 },
    width: { type: Number, default: 500 },
    height: { type: Number, default: 500 },
    isVisible: { type: Boolean, default: false },
    selectedTeamBrandId: { type: Number, default: 0 },
  },
  computed: {
    ...mapState(['isAddNewScene', 'showStoryboardOverlay']),
    ...mapState('assetsSidebar', ['activeDesignFormat']),
    ...mapState('canvasElements', ['elementsList']),
    ...mapState('team', ['teamId']),
    ...mapGetters('assetsSidebar', ['getActiveKeyword', 'getSidebarElements']),
    ...mapGetters('canvasElements', ['getProjectId', 'getActiveSceneId']),
    ...mapGetters('team', ['isInTeamWorkspace']),
    brandedDesignPreviewStyle() {
      const styleObject = {};

      styleObject.width = '100%';
      styleObject.paddingTop = `${(this.height / this.width) * 100}%`;

      return styleObject;
    },
    elements() {
      return [...this.scene.elements].reverse();
    },
    sceneScale() {
      return this.imageWidth / this.width;
    },
  },
  methods: {
    ...mapMutations(['setShowCanvasPreloader', 'setShowDesignContainer', 'setShowSidebar']),
    ...mapMutations('assetsSidebar', ['updateSidebarElementPreviewSceneData']),
    ...mapMutations('canvasElements', ['emptyActiveElements']),
    selectTemplate() {
      if (this.isAddNewScene) {
        // if user add from right sidebar add new scene button
        this.addTemplateScene();
      } else if (this.elementsList.allIds.length === 0) {
        // if there is no element, don't need warning
        this.replaceTemplateScene();
      } else {
        this.$root.$emit('show-confirm-design', { id: this.id });
      }
    },
    addTemplateScene() {
      const hitMax = this.checkForMaxScenes();
      if (hitMax) return;
      this.chooseTemplate();
    },
    replaceTemplateScene() {
      const hitMax = this.checkForMaxScenes(true);
      if (hitMax) return;
      this.chooseTemplate(true);
    },
    chooseTemplate(isReplace) {
      // NOTE!!
      // if you update this function, update the confirmNewTemplate() in ModularTemplate.vue and CategoryItem.vue too

      this.setShowCanvasPreloader(true);

      // console.log('chooseTemplate');
      // get scenes elements
      const templateId = this.id;

      const params = {
        project_id: this.getProjectId,
        override_selected_template: !this.showStoryboardOverlay,
        is_branded: true,
      };

      if (isReplace) {
        // if replacing, add active_scene_id
        params.active_scene_id = this.getActiveSceneId;
      }

      if (!this.isAddNewScene && !isReplace) {
        // if it's not from right sidebar add scene button, add after_scene_id
        params.after_scene_id = this.getActiveSceneId;
      }

      if (this.elementsList.allIds.length === 0) {
        // if there is no elements, auto change the canvas dimension
        params.change_canvas_dimension = true;
      }

      if (this.isInTeamWorkspace) {
        params.team_id = this.teamId;
        params.brand_id = this.selectedTeamBrandId;
      }

      console.log('params', params);
      console.log('Choose template', this.getProjectId);

      this.emptyActiveElements();

      ProjectApi.chooseTemplate(templateId, params)
        .then((response) => {
          if (response.data.success) {
            // console.log('response', JSON.parse(JSON.stringify(response.data.results)));
            this.applyDesign({
              response,
              isReplace,
            });
          } else {
            this.setShowCanvasPreloader(false);
          }

          this.trackData('templates', templateId); // track usage of template
        })
        .catch((error) => {
          console.log(error);
          // if failed, show the design option again
          this.setShowCanvasPreloader(false);
          this.setShowSidebar(false);
          this.setShowDesignContainer(true);
        });
    },
    checkPreviewSceneData() {
      /**
       * The elements will always be preloaded
       * but only get the scene elements if this is on the active page
       */
      if (!this.isVisible) {
        this.isRequestingApi = true;
        return;
      }

      // Check if scene info already exists
      const group = 'designs';
      const subgroup = 'designs';
      const slug = this.getActiveKeyword || 'all';
      const elementId = parseInt(this.id, 10);

      const sidebarElement = this.getSidebarElements[`${group}/${subgroup}/${slug}`].find(element => element.id === elementId);

      const params = {};
      if (this.isInTeamWorkspace) {
        params.team_id = this.teamId;
        params.brand_id = this.selectedTeamBrandId;
      }

      if (!sidebarElement.preview_scene_data || this.isInTeamWorkspace) {
        // Otherwise get scene info
        CollectionsApi.getItem(groups.BRANDEDDESIGNS, this.id, params)
          .then((response) => {
            this.updateSidebarElementPreviewSceneData({
              id: elementId,
              group,
              subgroup,
              slug,
              sceneData: response.data.results.scenes[0],
            });
            [this.scene] = response.data.results.scenes;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isRequestingApi = false;
          });
      } else {
        this.scene = sidebarElement.preview_scene_data;
        this.isRequestingApi = false;
      }
    },
    checkBeforeChooseTemplate({ id, isReplace }) {
      if (id === this.id) {
        this.chooseTemplate(isReplace);
      }
    },
  },
  beforeMount() {
    this.checkPreviewSceneData();
  },
  mounted() {
    this.$nextTick(() => {
      // assign after Slick carousel is init so we get the right size
      if (this.$refs.image) {
        this.imageWidth = this.$refs.image.offsetWidth;
        this.imageHeight = this.$refs.image.offsetHeight;
        this.$emit('branded-template-height', this.imageHeight);
      }
    });

    this.$root.$on('confirm-design', this.checkBeforeChooseTemplate);
  },
  beforeDestroy() {
    this.$root.$off('confirm-design', this.checkBeforeChooseTemplate);
  },
  watch: {
    selectedTeamBrandId() {
      this.isRequestingApi = true;
      this.isLoaded = false;
      this.checkPreviewSceneData();
    },
    isVisible(val) {
      // only load the preview scene data if this page is loaded
      if (val) this.checkPreviewSceneData();
    },
  },
};
</script>

<style lang="scss">
.branded-design--item__container {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: var(--lightgrey400-darkgrey600);
  transition: height 0.25s ease-in-out, background 0.25s 0.5s ease-in-out;
  border-radius: $componentBorderRadius;

  &:not(.is-loaded) {
    cursor: auto;
  }

  &.is-loaded {
    .branded-design--item {
      opacity: 1;
    }
  }

  .branded-design--item {
    opacity: 0;
    cursor: pointer;
    box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
    display: flex;
    width: 100%;
    position: relative;
    transition: opacity 0.25s 0.25s ease-in-out;

    .branded-design__preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.25s 0.25s ease-in-out, transform 0.25s ease-in-out;

      .branded-design__canvas {
        overflow: hidden;
        background: $light;
        position: relative;
        display: inline-block;
        user-select: none;
        transform-origin: top left;
        left: 0;
        top: 0;

        & * {
          pointer-events: none !important;
        }
      }

      .scene__element {
        * {
          pointer-events: none !important;
        }
      }
    }
  }

  .preloader {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
