<template lang="pug">
.design-item(
  :class="{ 'is-loaded': imageIsLoaded }"
  @mouseenter="mouseEnter"
  @mouseleave="mouseLeave"
)
  BasePreloader(
    :type=11
    v-if="!imageIsLoaded"
  )
  .design-item__preview(@click="click")
    .design-item__height-controller(:style="designItemHeightStyle")
    //- If there is a preview
    template(v-if="hasPreview")

      //- If it has video
      .design-item__video(
        v-if="showVideo"
        :class="{'is-loaded': videoIsLoaded}"
      )
        video(
          ref="video"
          :loop="true"
          :autoplay="false"
          :muted="true"
          :src="videoUrl"
        )
        Lottie.loader__animation(
          v-show="!videoIsLoaded"
          :options="canvasLoaderOptions"
        )

      img.design-item__image(
        ref="image"
        :src="svgPreview"
      )
    template(v-if="scenes.length > 1")
      span.overlay-box.total-scenes 1 of {{ scenes.length }}

</template>

<script>
import { homeUrl, _time } from '@/assets/scripts/variables';
import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue';
import CanvasLoader from '@/assets/data/canvas-loader.json';

export default {
  name: 'DesignItem',
  components: {
    Lottie,
  },
  data() {
    return {
      imageHeight: 100,
      imageIsLoaded: false,
      showLottiePreloader: false,
      showPreview: true,
      showVideo: false,
      videoIsLoaded: false,
    };
  },
  props: {
    id: [String, Number],
    template_type: { type: String, default: '' },
    preview_url: { type: String, default: '' },
    scenes: { type: Array, default: () => [] },
    video_preview_url: { type: String, default: '' },
    width: { type: Number, default: 500 },
    height: { type: Number, default: 500 },
  },
  computed: {
    designItemHeightStyle() {
      const styleObject = {};

      styleObject.width = '100%';
      styleObject.paddingTop = `${this.height / this.width * 100}%`;

      return styleObject;
    },
    hasPreview() {
      return this.preview_url !== '';
    },
    svgPreview() {
      if (!this.preview_url) return;
      let svgPreview = null;
      let url = '';

      if (this.preview_url.indexOf('http') !== -1) {
        svgPreview = this.preview_url;
        const newUrl = new URL(svgPreview);
        newUrl.searchParams.set('session', _time());
        url = newUrl.href;
      } else {
        // eslint-disable-next-line
        url = require(`@/assets/images/elements/${this.preview_url}`);
      }

      // return `${svgPreview}?session=${_time()}`;
      // eslint-disable-next-line
      return url;
    },
    downloadLink() {
      if (this.file) {
        // modified in api to return full link
        return this.file;
      }

      let arr = [];
      let link = '';
      if (this.upload) {
        arr = JSON.parse(this.upload);
      }

      if (arr[0] && arr[0].download_link) {
        link = arr[0].download_link;
      }

      // eslint-disable-next-line
      if (link && link.indexOf('http') == -1) {
        // TODO: fix when CORS issue is fixed
        // cannot use staging url cause of CORS issue
        if (
          this.elementType === 'videos'
          || this.fileExt(link) === 'svg'
          || this.fileExt(link) === 'webm'
        ) {
          link = `/storage/${link}`;
        } else if (this.fileExt(link) === 'json') {
          link = `/public/storage/${link}`;
        } else {
          link = `${homeUrl()}/public/storage/${link}`;
        }
      }

      return link;
    },
    videoUrl() {
      if (this.video_preview_url) {
        const url = new URL(this.video_preview_url);
        url.searchParams.set('session', _time());
        return url.href;
      }

      if (this.downloadLink && this.fileExt(this.downloadLink) === 'webm') {
        const url = new URL(this.downloadLink);
        url.searchParams.set('session', _time());
        return url.href;
      }

      if (this.file && this.fileExt(this.file) === 'mp4') {
        // for my video files, the video url is in this.file
        return this.file;
      }

      return '';
    },
    canvasLoaderOptions() {
      return {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'none',
        },
        animationData: CanvasLoader,
      };
    },
  },
  watch: {
    showVideo(val) {
      if (val) {
        this.$nextTick(() => {
          const { video } = this.$refs;
          if (video) {
            video.onloadedmetadata = () => {
              /**
               * make sure video should play or not before hiding preview
               * (or) it will become blank because of hiding both video and preview
               */
              video.play();
              this.showPreview = !this.showVideo;
              this.videoIsLoaded = true;
              this.$emit('item-is-loaded', true);
            };
          }
        });
      } else {
        const { video } = this.$refs;
        if (video) {
          video.pause();
          video.src = '';
          video.load();
        }
      }
    },
    imageIsLoaded(val) {
      if (val) {
        this.$emit('design-template-height', this.imageHeight);
      }
    },
  },
  beforeDestroy() {
    if (this.$refs.image) {
      this.$refs.image.removeEventListener('load', this.checkImageHeight);
    }
    this.$root.$off('confirm-design', this.checkBeforeChooseTemplate);
  },
  mounted() {
    if (this.$refs.image) {
      this.$refs.image.addEventListener('load', this.checkImageHeight);
    }
    this.$root.$on('confirm-design', this.checkBeforeChooseTemplate);
  },
  methods: {
    checkBeforeChooseTemplate({ id, isReplace }) {
      if (id === this.id) {
        this.$emit('choose', {
          type: this.template_type,
          id: this.id,
          isReplace,
        });
      }
    },
    checkImageHeight() {
      this.imageIsLoaded = true;
      const height = this.$refs.image && this.$refs.image.height ? this.$refs.image.height : 100;
      this.imageHeight = height;
    },
    mouseEnter() {
      this.showVideo = true;
      this.videoIsLoaded = false;
    },
    mouseLeave() {
      this.showPreview = true;
      this.showVideo = false;
      this.videoIsLoaded = false;
    },
    click() {
      this.$emit('click', {
        type: this.template_type,
        id: this.id,
      });
    },
  },
};
</script>

<style lang="scss">
.design-item {
  width: 100%;
  height: 96%;
  position: relative;
  overflow: hidden;
  background: var(--lightgrey400-darkgrey600);
  text-transform: capitalize;
  transition: height 0.25s ease-in-out, background 0.25s 0.5s ease-in-out;
  min-height: 40px;
  border-radius: $componentBorderRadius;

  &:not(.is-favourite) {
    .overlay-box {
      background: rgba(0, 0, 0, 0.6);
    }
  }

  &.is-loaded {
    height: auto;
  }

  &:nth-child(4n) {
    margin-right: 0;
  }

  .icon-modular {
    top: 8px;
  }

  .design-item__preview {
    position: relative;
    overflow: hidden;
    cursor: pointer;


    &::before {
      display: none;
    }

    .design-item__video video,
    .design-item__image {
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: center center;
      width: 100%;
      height: 100%;
      object-fit: cover;
      box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
    }
  }

  &.is-favourite {
    .design-item__preview::before {
      padding-top: 100%;
    }
  }

  &:hover {
    border-color: $blue;

    .design-item__video video,
    .design-item__image {
      transform: none;
    }
  }

  .design-item__bottom-overlay {
    transform: translateY(-100%);
  }

  &:hover {
    .design-item__top-overlay,
    .design-item__bottom-overlay {
      opacity: 1;

      button{
        opacity: 1;
      }
    }
  }

  &.is-loaded {
    background: transparent;

    .design-item__image {
      opacity: 1;
    }

    .is-preparing {
      // re-trimming old video permanently
      position: absolute;
      height: 100% !important;
      opacity: 0.9;
    }
  }

  .template-type {
    background: $blue;
    color: $light;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 4px;
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  div {
    width: 100%;
  }

  .design-item__video {
    position: absolute;
    z-index: 2;
    height: 100%;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &.is-loaded {
      background-image: none;

      video {
        opacity: 1;
      }
    }

    video {
      opacity: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 0.25s ease-in;
    }
  }

  .design-item__lottie {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $lightBg;
  }

  .btn-favourite.is-active {
    opacity: 1;
  }

  &:hover,
  &.is-hovering-file-options {
    .design-item__bottom-overlay::before {
      transform: translateY(0);
    }

    .overlay-box.total-scenes {
      opacity: 1;
    }
  }

  .overlay-box {
    position: absolute;
    min-width: 24px;
    height: 24px;
    background: $darkGrey;
    color: $light;
    padding: 3px 5px;
    line-height: 1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 8px;
    left: 8px;
    font-size: 0.75em;
    font-weight: 500;
    z-index: 3;

    &.icon {
      font-weight: 400;
    }

    &.total-scenes {
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      bottom: 8px;
      right: 8px;
      left: auto;
      text-transform: uppercase;
      padding: 2px 7px;
    }
  }

  .preloader {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0.5) !important;

    svg {
      transform: scale(0.5) !important;
    }
  }
}

.design-item__preview {
  .loader__animation {
    width: 60px !important;
    height: 60px !important;
    position: absolute;
    z-index: 3;
    height: 100%;
    margin: auto;
    left: -8px;
    bottom: -8px;
  }
}

.design-item__image {
  opacity: 0;
  transition: opacity 0.25s 0.25s ease-in-out, transform 0.25s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transform-origin: center center;
}
</style>
