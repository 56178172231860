<template lang="pug">
.design-overlay
  CanvasModal(
    icon="icon-template-menu"
    :title="canvasModalTitle"
    :is-show="isShow"
    :is-grey="true"
    :is-inner-overlay="isOpenColor || isOpenFilter"
    @closeOverlay="closeOverlay"
  )
    //- added v-if="isShow" so that it doesn't
    //- needlessly being called when not opened
    .design-topbar(
      v-if="isShow && !showModular"
    )
      .design-topbar__tabs
        BaseButton.tab__item(
          v-for="(tab, index) in tabs"
          :class="{'designer-templates': tab.name === 'Designer Templates', 'is-dark': isDarkMode, 'is-active': activeDesignTab === tab.value }"
          :key="index"
          :is-primary="!isDarkMode"
          :is-success="isDarkMode"
          :is-canvas="true"
          :is-hollow="true"
          padding="14px 24px"
          type="button"
          @click="changeTab(tab.value)"
        ) {{ tab.name }}

      .design-topbar__options.team-brand__dropdown-wrapper(
        v-if="isShowTeamBrandDropdown"
        :class="{'is-disabled': !selectedTeamBrandId}"
      )
        label.dropdown-label Team Brand :
        BaseDropdown.team-brand__dropdown(
          :options="teamBrandDropdownOptions"
          :value="selectedTeamBrandId"
          @change="switchTeamBrand"
        )

      .design-topbar__options(v-if="showFilterAndSearchBar")
        AssetsSearch(
          :is-light="!isDarkMode"
        )
        DesignColors(
          :class="{ 'is-light': !isDarkMode, 'is-open': isOpenColor }"
          @filter="filterDesigns"
          @is-open-color="updateOpenColor"
        )
        DesignFilters(
          :class="{ 'is-light': !isDarkMode, 'is-open': isOpenFilter }"
          @filter="filterDesigns"
          @is-open-filter="updateOpenFilter"
        )
    //- added v-if="isShow" so that it doesn't
    //- needlessly being called when not opened
    .design-overlay__content(
      v-if="isShow"
    )
      //- this wrapper is to ensure the ratio of the width and height
      //- always the same
      .design-overlay__wrapper(
        :style="designOverlayStyle"
      )
        .wrapper
          template(v-if="isShowBrandedDialog")
            .design-overlay__brand-image
              img(src="@/assets/images/branded-designs/preview.jpg")
            BaseDialog.design-overlay__brand-dialog(
              :class="{'dialog--button-long': isTeamMember && !hasTeamBrands}"
              :is-show="true"
              :text="setupBrandModalText"
              :button1Text="setupBrandModalConfirmText"
              button2Text="Not Now"
              :no-button="isHideSetupBrandModalButtons"
              :no-cancel="isHideSetupBrandModalCancel"
              @confirm="onConfirmSetupBrandModal"
              @closeOverlay="goToAllTemplates"
            )
              div.team-brand__dropdown-wrapper.wrapper--modal(v-if="isShowTeamBrandDropdown")
                BaseDropdown.team-brand__dropdown(
                  :options="teamBrandDropdownOptions"
                  :value="selectedTeamBrandId"
                  @change="switchTeamBrand"
                )
          template(v-else)
            BasePreloader.design-overlay__preloader(
              v-if="showDesignPreloader || presetFormatsNotLoaded"
            )
            //- HOW DESIGN LIST WORKS
            //- check getFilteredTemplates()
            template(v-else)
              p.design-overlay__empty(
                v-if="presetFormatsDesigns.length === 0"
              ) There is no result available.
              template(v-else)
                ModularTemplate(v-if="showModular")
                DesignList(
                  v-else
                  :is-branded-tab="isBrandedTab"
                  :active-filtered-design="activeFilteredDesign"
                  :selected-team-brand-id="selectedTeamBrandId"
                  @design-modal-height="setDesignModalHeight"
                )
    TemplateWarning(
      v-if="showTemplateWarning"
      @cancel="cancelTemplate"
      @add="addTemplateScene"
      @replace="replaceTemplateScene"
    )
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { debounce } from 'debounce';
import { designTabs } from '@/assets/scripts/enums';

import AssetsSearch from '@/components/ProjectCanvas/AssetsSidebar/children/AssetsSearch.vue';
import DesignList from '@/components/ProjectCanvas/DesignListing/DesignList.vue';
import DesignFilters from '@/components/ProjectCanvas/DesignListing/children/DesignFilters.vue';
import DesignColors from '@/components/ProjectCanvas/DesignListing/children/DesignColors.vue';
import TemplateWarning from '@/components/ProjectCanvas/AssetsSidebar/children/TemplateWarning.vue';
import ModularTemplate from '@/components/ProjectCanvas/PageOverlay/ModularTemplate.vue';

import newSceneMixins from '@/components/mixins/new-scene-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'DesignOverlay',
  components: {
    AssetsSearch,
    DesignFilters,
    DesignList,
    DesignColors,
    TemplateWarning,
    ModularTemplate,
  },
  mixins: [newSceneMixins, darkModeMixin],
  data() {
    return {
      isShow: false,
      isFilterActive: false,
      showDesignList: false,
      tabs: [
        {
          name: 'Branded Templates',
          value: designTabs.BRANDEDDESIGNS,
        },
        {
          name: 'Designer Templates',
          value: designTabs.ALLDESIGNS,
        },
      ],
      templateWarningId: -1,
      isFormatLoading: false,
      presetFormatsNotLoaded: true,
      isOpenColor: false,
      isOpenFilter: false,
      designModalHeight: -1,
      isResizingModal: false,

      selectedTeamBrandId: 0,
    };
  },
  computed: {
    ...mapState(['showModularTemplate', 'showLastSelectedTemplate', 'showTemplateWarning', 'previousDesignContainerPage']),
    ...mapState('assetsSidebar', [
      'activeDesignTab',
      'activeDesignCategoriesColorId',
      'activePresetFormatId',
      'designsByPresetFormat',
      'filteredDesigns',
      'filteredBrandedDesigns',
      'showDesignPreloader',
      'presetFormats',
    ]),
    ...mapState('team', ['teamSlug']),
    ...mapGetters('assetsSidebar', [
      'getActiveCategory',
      'getActiveGroup',
      'getActiveKeyword',
      'getActivePresetFormat',
      'getActiveSubGroup',
      'getPresetFormats',
      'getSidebarElements',
    ]),
    ...mapGetters('brand', ['hasSetupBrand']),
    ...mapGetters('canvasElements', ['getProjectId', 'getCanvasSize']),
    ...mapGetters('team', [
      'isInTeamWorkspace',
      'isTeamMember',
      'hasTeamBrands',
      'getActiveTeamBrandId',
      'getTeamBrands',
    ]),
    setupBrandModalText() {
      let text = 'Set up your brand to start creating personalised templates.';
      if (this.isInTeamWorkspace) {
        if (this.hasTeamBrands) text = 'Select your team brand to start creating personalised templates.';
        if (!this.hasTeamBrands && this.isTeamMember) text = 'Only Team Owner and Team Designers are able to create team brands.';
        if (!this.hasTeamBrands && !this.isTeamMember) text = 'Set up your team brand to start creating personalised templates.';
      }

      return text;
    },
    setupBrandModalConfirmText() {
      if (this.isTeamMember && !this.hasTeamBrands) return 'Use Designer Templates';
      return 'Set Up';
    },
    hasKeywordOrFormat() {
      return !!this.getActiveKeyword || this.activePresetFormatId !== -1;
    },
    showModular() {
      return this.showModularTemplate || this.showLastSelectedTemplate;
    },
    presetFormatsDesigns() {
      // only return if there is designs
      const array = [];
      Object.keys(this.designsByPresetFormat).forEach((id) => {
        const designs = this.designsByPresetFormat[id];
        if (designs.length) {
          array.push(id);
        }
      });
      return array;
    },
    presetFormatsDesignsIds() {
      const activePresetFormat = this.presetFormats.byId[this.activePresetFormatId];
      if (!activePresetFormat) return [];
      return this.isBrandedTab ? activePresetFormat.brandedTemplates : activePresetFormat.templates;
    },
    isBrandedTab() {
      return this.activeDesignTab === designTabs.BRANDEDDESIGNS;
    },
    showFilterAndSearchBar() {
      return !this.isBrandedTab && !this.showModular;
    },
    activeFilteredDesign() {
      return this.isBrandedTab ? this.filteredBrandedDesigns : this.filteredDesigns;
    },
    canvasModalTitle() {
      return this.showModular ? 'Select Scene' : 'Templates';
    },
    designOverlayStyle() {
      const styleObject = {};

      if (this.designModalHeight !== -1 && !this.isResizingModal) {
        styleObject.height = this.designModalHeight;
      }
      return styleObject;
    },
    isShowBrandedDialog() {
      if (this.isInTeamWorkspace) return this.isBrandedTab && !this.selectedTeamBrandId;
      return this.isBrandedTab && !this.hasSetupBrand;
    },
    isHideSetupBrandModalButtons() {
      return this.isInTeamWorkspace && this.hasTeamBrands;
    },
    isHideSetupBrandModalCancel() {
      return this.isShowTeamBrandDropdown || this.isTeamMember;
    },
    isShowTeamBrandDropdown() {
      return this.isBrandedTab && this.isInTeamWorkspace && this.hasTeamBrands;
    },
    teamBrandDropdownOptions() {
      let options = [];

      options = this.getTeamBrands.map((brand) => { // eslint-disable-line
        return {
          value: brand.id,
          name: brand.brand_name,
        };
      });

      return options;
    },
  },
  methods: {
    ...mapMutations([
      'setShowDesignContainer',
      'setShowModularTemplate',
      'setShowSidebar',
      'setShowTemplateWarning',
      'setPreviousDesignContainerPage',
    ]),
    ...mapMutations('assetsSidebar', [
      'resetFilteredDesign',
      'setActiveCategory',
      'setActiveDesignTab',
      'setActiveGroup',
      'setActivePresetFormatId',
      'setDesignsByPresetFormat',
      'setFilteredDesigns',
      'setShowDesignPreloader',
    ]),
    ...mapActions('assetsSidebar', ['getDesigns']),
    ...mapActions('canvasElements', ['saveProject']),
    setDesignModalHeight(height) {
      this.designModalHeight = height;
    },
    compareDays(date) {
      const differenceInMilliseconds = new Date() - new Date(date);
      const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

      return differenceInDays;
    },
    closeOverlay() {
      this.isShow = false;

      setTimeout(() => {
        this.setShowDesignContainer(false);
        this.setShowModularTemplate(false);
        this.setActiveGroup('texts'); // to cover issue where no tab is opened on sidebar after opened design-overlay from add scene button in storyboard then close
      }, 200);
    },
    updateOpenColor(value) {
      this.isOpenColor = value;
    },
    updateOpenFilter(value) {
      this.isOpenFilter = value;
    },
    toggleFilter() {
      this.isFilterActive = !this.isFilterActive;
    },
    changeActiveCategory(category) {
      this.setActiveCategory(category);
    },
    filterDesigns() {
      let page = 7;
      if (this.previousDesignContainerPage) page = this.previousDesignContainerPage + 3; // ensuring subsequent of 2 pages to not being lazy loaded / working as usual when last page tracking system enabled
      this.setFilteredDesigns({ page, isBranded: this.isBrandedTab });
    },
    viewAllDesigns() {
      // load the first 6 pages
      this.filterDesigns();
      this.setShowDesignPreloader(true);
      setTimeout(() => {
        this.setShowDesignPreloader(false);
      }, 1500);
    },
    cancelTemplate() {
      this.setShowTemplateWarning(false);
      this.templateWarningId = -1;
    },
    addTemplateScene() {
      const hitMax = this.checkForMaxScenes();
      if (hitMax) return;
      // before adding new scene, make sure previous scene is saved correctly.
      this.saveProject();

      this.$root.$emit('confirm-design', {
        id: this.templateWarningId,
      });
      this.setShowTemplateWarning(false);
    },
    replaceTemplateScene() {
      const hitMax = this.checkForMaxScenes(true);
      if (hitMax) return;
      this.$root.$emit('confirm-design', {
        id: this.templateWarningId,
        isReplace: true,
      });
      this.setShowTemplateWarning(false);
    },
    checkIfThereIsSidebarElements(val) {
      setTimeout(() => {
        const keyword = val || 'all';
        const sidebar = this.getSidebarElements[`designs/designs/${keyword}`];

        if (!sidebar) {
          this.checkIfThereIsSidebarElements(keyword);
        } else {
          // if there is preset format selected, check the filtered designs
          this.setDesignsByPresetFormat();
          this.filterDesigns();
          this.setShowDesignPreloader(false);
        }
      }, 750);
    },
    updateDesignsList() {
      if (this.presetFormatsNotLoaded) return;
      console.log('updateDesignsList');
      this.checkIfThereIsSidebarElements(this.getActiveKeyword);
    },
    onConfirmSetupBrandModal() {
      if (this.isTeamMember && !this.hasTeamBrands) {
        this.goToAllTemplates();
      } else {
        this.goToBrandSetup();
      }
    },
    goToBrandSetup() {
      let brandSetupUrl = `${process.env.VUE_APP_DASHBOARD_URL}/brand-setup?return=${this.getProjectId}`;
      if (this.isInTeamWorkspace) brandSetupUrl = `${process.env.VUE_APP_DASHBOARD_URL}/team/${this.teamSlug}/team-brands`

      window.location.href = brandSetupUrl;
    },
    goToAllTemplates() {
      this.setActiveDesignTab(designTabs.ALLDESIGNS);
    },
    changeTab(value) {
      this.setPreviousDesignContainerPage(0);
      if (value === designTabs.BRANDEDDESIGNS && !this.hasSetupBrand) {
        // if user going to branded tab and doesn't have brand yet
        // save so that if user choose to go to brand page, the file is already saved
        this.saveProject();
      }
      this.setActiveDesignTab(value);
    },
    compareCanvasSizeToPreset() {
      // always set preset format id when opening canvas
      let squareId = -1;

      // eslint-disable-next-line
      for (let i = 0, { length } = this.getPresetFormats; i < length; i++) {
        const format = this.getPresetFormats[i];
        const { width, height } = this.getCanvasSize;

        if (format.width === format.height) {
          squareId = format.id;
        }

        // eslint-disable-next-line
        if (format.width / format.height == width / height) {
          this.setActivePresetFormatId(format.id);
          break;
        }

        // if it managed to get here without breaking, means none of the size match
        // user square instead
        if (i === length - 1) {
          this.setActivePresetFormatId(squareId);
        }
      }
    },
    // eslint-disable-next-line func-names
    handleResize: debounce(function () {
      // don't need to refresh the size if it's showing modular
      if (this.showModular) return;

      this.setShowDesignPreloader(true);
      this.updateDesignsList();
    }),
    showConfirmDesign({ id }) {
      this.setShowTemplateWarning(true);
      this.templateWarningId = id;
    },
    switchTeamBrand(value) {
      this.selectedTeamBrandId = value;
    },
  },
  mounted() {
    this.checkIfThereIsSidebarElements(this.getActiveKeyword);
    this.isShow = true;

    this.$root.$on('show-confirm-design', this.showConfirmDesign);

    window.addEventListener('resize', this.handleResize);

    setTimeout(() => {
      // TODO:
      // this is dirty way cause sometimes it's not loading properly
      // if after 3 sec, it's still empty, reload
      if (!this.activeFilteredDesign || !this.activeFilteredDesign.length) this.handleResize();
    }, 4000);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    this.$root.$off('show-confirm-design', this.showConfirmDesign);
  },
  watch: {
    showDesignContainer(val) {
      this.isShow = val;
    },
    showDesignPreloader(val) {
      this.isResizingModal = val;
    },
    getActiveCategory: {
      handler() {
        // console.log('!!! getActiveCategory', val.id);
        this.updateDesignsList();
      },
      deep: true,
    },
    activeDesignTab(val) {
      console.log('!!! activeDesignTab', val, this.presetFormatsNotLoaded);
      this.setShowDesignPreloader(true);
      if (
        this.presetFormatsNotLoaded
        && this.presetFormatsDesignsIds
        && this.presetFormatsDesignsIds.length
      ) {
        this.presetFormatsNotLoaded = false;
        setTimeout(() => {
          this.updateDesignsList();
        }, 1000);
      } else {
        this.updateDesignsList();
      }
    },
    activePresetFormatId: {
      handler() {
        this.updateDesignsList();
      },
      immediate: true,
    },
    activeDesignCategoriesColorId() {
      // console.log('!!! activeDesignCategoriesColorId', val);
      this.setShowDesignPreloader(true);
      this.updateDesignsList();
    },
    getActiveKeyword(val) {
      this.setShowDesignPreloader(true);

      const keyword = val || 'all';

      if (!this.getSidebarElements[`designs/designs/${keyword}`]) {
        // only get designs if it's not available yet
        this.getDesigns({ keyword });
        this.checkIfThereIsSidebarElements(this.getActiveKeyword);
      } else {
        this.updateDesignsList();
      }
    },
    presetFormatsDesignsIds: {
      handler(val) {
        if (val && val.length && this.presetFormatsNotLoaded) {
          /**
           * this function is called when overlay appears before all the preset format is loaded
           * so if there is still no preset templates yet, don't trigger design list
           */
          this.presetFormatsNotLoaded = false;
          // need to set time out to ensure all presets are ready
          setTimeout(() => {
            this.updateDesignsList();
          }, 1000);
        }
      },
      deep: true,
      immediate: true,
    },
    getPresetFormats: {
      handler(val) {
        if (val && val.length) {
          this.compareCanvasSizeToPreset();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.design-overlay {
  .canvas-modal__overlay {
    background: rgba(0, 0, 0, 0.8);
  }

  .canvas-modal__content {
    height: auto;
    width: 80vw;
    max-height: none;
    max-width: none;
    background: var(--canvasbg-darkgrey700);
    animation: slideDown 0.65s cubic-bezier(0.12, 0.71, 0.13, 0.98);
  }

  .canvas-modal__header {
    padding: 30px 0 0 40px;
    color: var(--black-white) !important;
    background: var(--canvasbg-darkgrey700) !important;

    .title {
      font-weight: 500;
    }

    .icon-template-menu {
      color: var(--blue700-white) !important;
    }
  }

  .search-container {
    z-index: 99;

    .tab-search {
      font-size: 0.875rem !important;

      &.is-category-search {
        @include smallest() {
          font-size: 0.875rem !important;
        }

        &:before {
          display: none;
        }

        .form-search-box {
          padding-left: 10px;

          @include smallest() {
            padding-left: 10px;
          }
        }
      }

      .form-input {
        color: $darkGrey;
      }
    }
  }

  .content {
    padding: 10px 30px 0 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .canvas-modal {
    text-align: left;
  }

  .canvas-modal__header {
    .title {
      width: calc(100% - 400px);
    }
  }

  .canvas-modal__closebtn {
    top: 32px;
    color: var(--black-white) !important;
    right: 30px;
  }

  .slick-arrow {
    position: absolute;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background: $grey;
    color: $light;
    z-index: 2;
    top: 50%;
    text-indent: 10000px;
    overflow: hidden;
    transition: background 0.25s ease-in-out;

    &:hover {
      background: darken($grey, 5%);
    }

    &:focus {
      background: $grey;
    }

    &.slick-disabled {
      display: none !important;
    }

    &::after {
      content: '';
      font-family: 'icomoon';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-indent: 0;
      font-size: 24px;
      height: 30px;
    }
  }

  .slick-prev {
    left: -10px;
    transform: translateY(-50%);

    &::after {
      content: $icon-left-arrow;
    }
  }

  .slick-next {
    right: -10px;
    transform: translateY(-50%);

    &::after {
      content: $icon-right-arrow;
    }
  }

  .slick-slide {
    padding: 0 5px;
  }

  .slick-list {
    margin-top: -10px;
  }

  .slick-track {
    padding: 10px 0;
  }

  .design-overlay__brand-dialog {
    pointer-events: none;

    &.dialog--button-long {
      .btn {
        max-width: 250px;
      }
    }

    .dialog-modal__overlay {
      display: none;
    }

    .dialog-modal__content {
      pointer-events: auto;
    }
  }

  .team-brand__dropdown-wrapper {
    display: flex;
    align-items: center;

    &.is-disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &.wrapper--modal {
      justify-content: center;
      margin-top: 25px;

      .team-brand__dropdown {
        .dropdown__selected {
          border-color: var(--blue700-white);
        }
      }
    }

    .dropdown-label {
      color: var(--darkGrey800-lightgrey300);
      font-size: 0.875rem;
      margin-right: 10px;
      font-weight: 400;
    }

    .team-brand__dropdown {
      min-width: 200px;

      .dropdown__selected {
        color: var(--darkGrey800-lightgrey300);
        border-color: var(--blue700-green700);
        background: transparent;
        padding-right: 25px;
      }

      .dropdown__list {
        position: absolute;
        z-index: 1;
        width: 100%;
        background: var(--white-darkgrey900);
        max-height: 35vh;
        overflow: auto;
      }

      .dropdown__item {
        color: var(--darkGrey800-lightgrey300);
        border-bottom: 1px solid var(--lightgrey300-darkgrey7);
        background: transparent !important;
        word-break: break-word;
        display: flex;
        align-items: center;
        padding-right: 40px;

        &:last-of-type {
          border: 0;
        }

        &.is-selected{
          &::after {
            content: $icon-checked;
            font-family: 'icomoon';
            position: absolute;
            right: 15px;
            font-size: 1rem;
            color: $green700;
            font-weight: normal;
          }
        }

        &:hover {
          background: var(--lightgrey300-darkgrey800) !important;
          color: var(--darkgrey800-lightgrey900);
          transition: color .2s ease-in-out;
        }
      }
    }
  }
}

.design-overlay__wrapper {
  position: relative;
  padding-bottom: 50px;

  &::after {
    content: '';
    display: block;
    padding-top: 46%;
  }

  .wrapper {
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.design-topbar {
  display: flex;
  padding: 5px 0;
  margin-left: 12px;
  align-items: center;
  justify-content: space-between;
}

.design-overlay__content {
  padding-top: 10px;
}

.design-topbar__button {
  font-size: 0.875rem;
  padding: 10px 5px;
  margin-right: 20px;
  color: $inputLabel;
}

.design-topbar__filter-button {
  .icon {
    position: relative;
  }

  &.is-active {
    .icon {
      transform: rotate(180deg);
    }
  }
}

.design-topbar__dropdown {
  position: relative;

  &.is-light {
    .dropdown__button {
      &:hover {
        color: $black;
      }
    }
  }

  .dropdown__button {
    margin-right: 10px;
    max-height: 32px;
    position: relative;
    min-width: 100px;
    color: var(--black-white);
    border-color: var(--black-white);

    &:hover {
      color: $black;
    }

    &.btn--primary {
      &:hover {
        color: $light;
      }
    }

    .icon {
      position: relative;
    }

    .icon-cancel {
      padding: 5px;
      cursor: pointer;
      margin-left: 5px;
      margin-right: -10px;
    }
  }

  .dropdown__content {
    position: absolute;
    z-index: 5;
    width: 805px;
    padding: 20px 15px;
    background: var(--white-darkgrey5);
    box-shadow: 0px 18px 18px rgba(10, 31, 68, 0.12), 0px 0px 2px rgba(10, 31, 68, 0.1);

    &--short {
      width: 170px;
      padding: 10px;
    }

    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out, transform 0.25s ease-in-out;
  }

  &.is-active {
    .dropdown__content {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    &:not(.has-value) {
      .dropdown__button {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  &.design-topbar__colors {
    &.is-open {
      .dropdown__button {
        z-index: 6;
      }
    }

    .dropdown__content {
      left: 0;
    }
  }

  &.design-topbar__filters {
    &.is-open {
      .dropdown__button {
        z-index: 6;
      }
    }

    .dropdown__content {
      // Note: If have .design-topbar__tabs => left -704px; , if doest have => left: -225px;
      left: -704px;
    }
  }
}

.design-topbar__tabs {
  margin-right: 10px;

  .tab__item {
    font-size: 13px;
    margin-right: 10px;
    transition: background-color 0.3s cubic-bezier(0, 0, 0, 1), color 0.3s cubic-bezier(0, 0, 0, 1),
      border-color 0.3s cubic-bezier(0, 0, 0, 1);

    &.is-active,
    &:active,
    &:hover {
      background: var(--blue700-green700) !important;
    }

    &:not(.is-active) {
      &.is-dark {
        color: $darkGrey400;
        border-color: $darkGrey400;

        &:hover {
          color: $light;
          border-color: $green700;
        }
      }
    }
  }

  .designer-templates {
    &:focus {
      background: $blue700;
      color: $light;
    }
  }
}

.design-topbar__options {
  display: flex;
  align-items: center;

  .assets__search-container {
    width: 300px;
    padding: 0;
    border-radius: 4px;
    margin-right: 10px;

    .tab-search {
      height: 44px !important;
    }
  }
}

.design-topbar__size {
  margin-right: 5px;
  border: 2px solid $grey;

  .tab__item.is-active & {
    border-color: $darkGrey;
  }

  &--square {
    width: 15px;
    height: 15px;
  }
  &--vertical {
    width: 15px;
    height: 30px;
  }
  &--landscape {
    width: 30px;
    height: 15px;
  }
}

.design-overlay__empty {
  font-size: 0.875em;
  margin: 50px auto;
  text-align: center;
  color: var(--black-white);
}

.design-overlay__brand-image {
  opacity: 0.2;
  height: 100%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
}

.design-overlay__preloader {
  margin-top: -20px;
}

@keyframes slideDown {
  0% {
    opacity: 0.35;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
